<template>
    <div class="sidebar-profile">
        <img :src="getProfileImage" width="70" style="border-radius: 50%;" />
        <div>
            <p class="name">{{ getProfile.fullName }}</p>
            <p class="division">{{ getProfile.workPosition }}</p>
        </div>
    </div>
    <div v-if="Object.entries(contactsBirthday).length" class="sidebar-birthdays">
        <h4>{{$t('birthday_title')}}</h4>
        <dl class="list list--birthdays">
            <template v-for="(list,idx) in contactsBirthday" :key="idx">
            <dt>{{$filters.formatDate(idx, 'j. F') }}</dt><dd><strong><template v-for="(cntct,idx) in list" :key="idx"><template v-if="idx>0">, </template>
                <router-link :to="{path: '/profil/' + cntct.uid}">{{ cntct.firstName }} {{ cntct.lastName }}</router-link></template></strong></dd>
            </template>
        </dl>
    </div>

    <div>
        <template v-for="(events, day) in events" :key="day">
        <h4>{{ $filters.formatDate(day, 'D, j. F Y') }}</h4>
        <template v-for="evt in events" :key="evt.uid">
            <SmallCalendar :item="evt" />
        </template>
        </template>
    </div>
    <div v-if="this['favorites/getFavorites'][0].length > 0">
        <h4>{{$t('favorites')}}</h4>
        <ul class="list list--contact">
            <li class="list-item" v-for="(cntct,idx) in this['favorites/getFavorites'][0]" :key="idx" >
                <ContactBox  :contact="cntct" @toggleContact="openProfil" />
            </li>
        </ul>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import SmallCalendar from './Subparts/smallCalendar';
import ContactBox from './Subparts/ContactBox';


export default {
	name: 'MainSidebar',
	components: {
		ContactBox,
		SmallCalendar
	},
	computed: {
		...mapGetters([ 'getProfileImage', 'getProfile', 'favorites/getFavorites', 'myEvents', 'commingEvents', 'contactsBirthday' ]),
        events: function(){
            let events = this.commingEvents;
			let list = {};
            events.forEach(( item ) => {
					let date = new Date(item.start * 1000);
					date.resetTo('hour');
					let idx = date.getTime() / 1000;
					if ( false === Object.prototype.hasOwnProperty.call(list, idx) ) {
						list[ idx ] = [];
					}
					list[ idx ].push(item);
				}, list);
            return (Object.fromEntries( Object.entries( list).slice(0,3) ));
        }
	},
    methods: {
		openProfil: function ( data ){
			this.$router.push({path:'/profil/' + data.uid})
        }
    }
};
</script>
<style scoped>
.sidebar-profile {
    display: flex;
    column-gap: 16px;
    align-items: center;
}
.sidebar-profile img{
    border-radius: 50%;
}
.sidebar-profile .name{
    font-size: var(--font-size-xl);
    color: var(--color-primary);
    font-weight: 700;
}

.sidebar-profile .division {
    margin-top: 0;
    font-size: var(--font-size-lg);
    font-weight: 700;
    color: var(--color-text-light);
}


</style>