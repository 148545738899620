<template>
    <div ref="lightbox" class="lightbox" :class="{'lightbox--seamless':seamless}" @click.self="close" v-show="show">
        <transition name="lightbox">
        <div class="lightbox-contentwrap" v-show="show">
            <span class="lightbox-close" @click="close"><inline-svg :src="require('@/assets/images/ic_close.svg')" /></span>
            <div class="lightbox-content"><slot name="content" @close="close" /></div>
            <slot name="footer"></slot>
        </div>
        </transition>
    </div>
</template>

<script>
import EventsBus from '../../utils/EventsBus';

export default {
    name: "Lightbox",
    data() {
        return {
			show: false
        };
    },
    props: {
        isVisible: {type: Boolean, default: false},
        seamless: {type: Boolean, default: false},
    },
    methods: {
        close: function ( ) {
			this.$emit('close');
			EventsBus.trigger('lightbox:close');
			this.show = false;
			document.querySelector('body').classList.toggle('no-scroll',false);
		}
    },
    computed: {},
    watch:{
		isVisible: function( val ){
			document.querySelector('body').classList.toggle('no-scroll',val);
			this.show = val;
			if( this.show )
			EventsBus.trigger('lightbox:open');
        }
    },
    created() {
		EventsBus.on('overlay:close', this.close);
    },
    mounted() {

    }
};
</script>

<style scoped>
@keyframes slide-left {
    from {
        transform: translate(100vw, 0);
    }
    to {
        transform: translate(0, 0);
    }
}
@media( min-width: 1024px) {
    @keyframes slide-left {
        from {
            transform: translate(100vw, -50%);
        }
        to {
            transform: translate(-50%, -50%);
        }
    }
}
.lightbox-contentwrap{
    will-change: transform;
}
.lightbox-enter-active,
.lightbox-leave-active {
    will-change: transform;
    animation: slide-left 0.5s ease-out forwards;
}

.lightbox-leave-active {
    animation-direction: reverse;
}
</style>
