<template>
    <div class="content-block">
        <div class="block-header"><h2>Personen, die du kennen könntest</h2></div>
        <Slider key="randomprofiles" max-width="235px" min-width="200px" v-if="profiles.length > 0" :controls="true" :pagination="false">
            <div v-for="(item,idx) in profiles" :key="idx" class="slide">
                <router-link :to="{path: '/profil/' + item.uid}" class="box contact contact--simple ">
                    <img :src="item.profileImage" v-if="item.profileImage" alt="" class="contact-image" />
                    <span v-else class="contact-image contact-image--placeholder">{{ initials(item) }}</span>
                    <h2 class="contact-name">{{ item.firstName }} {{ item.lastName }}</h2>
                    <p class="contact-info">{{ item.division }}</p>
                </router-link>
            </div>
        </Slider>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import ApiCall, { ApiDefaults } from '../../utils/api_call';
import Slider from '../../components/Subparts/Slider';

export default {
	name: 'ProfileRandom',
	data: function () {
		return {
			profiles: []
		};
	},
	components: {
		Slider
	},
	methods: {
		initials: function ( data ) {
			return data.firstName[ 0 ] + data.lastName[ 0 ];
		},
		fetchData: debounce(function () {
			let apiParams = Object.assign({}, ApiDefaults, {
				method: 'get',
				params: {
					id: 7,
					type: 10,
					'tx_c3json_jsondatauser[action]': 'random'
				}
			});

			ApiCall(apiParams).then(( response ) => {
				if ( response.status === 200 ) {
					this.profiles = response.data.user;
				}
			});
		}, 200)
	},
	created() {
		this.fetchData();
	}
};
</script>
<style scoped>
.block-header {
    margin-bottom: 16px;
}


</style>