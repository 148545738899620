<template>
    <div class="box box--compact_calendar">
<!--        <h3 class="date" v-if="! isBirthday">{{ $filters.formatDate(eventData.start,'j. F Y')}}</h3>-->
<!--        <h3 class="date" v-else>{{ $filters.formatDate(eventData.start,'j. F')}}</h3>-->
        <div class="box-header" @click="toggleBox">
            <p class="title">{{ eventData.title }}</p>
            <p class="box-time box-time--day" v-if="fullDay"><span>Ganztags</span><span></span></p>

            <p class="box-time" v-else-if="sameDay"><span>{{ dateFormat(eventData.start, 'H:i') }}</span>
                <span></span> <span>{{ dateFormat(eventData.end, 'H:i') }}</span></p>
            <p class="box-time" v-else><span>{{ dateFormat(eventData.start, 'j.m.') }}</span><span></span><span>{{ dateFormat(eventData.end, 'j.m.') }}</span></p>

            <div class="box-info">
                <p v-show="eventData.organizer">
                    <inline-svg :src="require('@/assets/images/ic_organizer.svg')" v-if="! isBirthday" alt="Veranstaltungsort" width="24" />
                    <inline-svg :src="require('@/assets/images/ic_birthday.svg')" v-else alt="Geburtstag" width="24" />
                    <span v-html="eventData.organizer"></span></p>
                <p v-show="eventData.location"><inline-svg :src="require('@/assets/images/ic_location.svg')" alt="Veranstaltungsort" width="24" /> <span v-html="eventData.location"></span></p>
<!--                <p v-if="! isLiked && likeEnabled">-->
<!--                    <button @click.stop="addLike" :class="{'signedUp': isLiked}" v-if="likeEnabled"  class="button button&#45;&#45;primary" v-html="!isLiked ? 'Teilnahme bestätigen':'Teilnahme bestätigt'"></button>-->
<!--                </p>-->
            </div>
        </div>
    </div>
</template>

<script>

import api, { ApiDefaults } from '../../utils/api_call';
import { ADD_MY_EVENT, REMOVE_MY_EVENT } from '../../utils/store/actions/general';

export default {
	name: "SmallCalendar",
	props: [ 'item' ],
	data() {
		return {
			eventData: this.item,
			open: true
		};
	},
	components: {
		// Downloadlist
	},
	computed: {
		isBirthday: function(){
			return Object.prototype.hasOwnProperty.call(this.eventData,'birthday')
		},
		generatedTeaser: function(){
			if( this.eventData.teaser ) return this.eventData.teaser;

			return this.eventData.bodytext.toString().substring(0,60) + ( this.eventData.bodytext.toString().length > 60 ? "&hellip;" : '');

        },
		now: function() {
			return Math.ceil(new Date().getTime() / 1000);
		},
		sameDay: function () {
			let start = new Date();
			start.setTime(this.eventData.start * 1000);

			let end = new Date();
			end.setTime(this.eventData.end * 1000);

			return start.isSame(end, 'day');
		},
		fullDay: function () {
			return this.eventData.start === this.eventData.end;
		},
		likeEnabled: function () {
			return (this.eventData.start > this.now && this.eventData.likes_allowed === true);
		},
		isLiked: function () {
			return this.eventData.likes.map(( i ) => i.uid).indexOf(this.$store.getters.getProfile.uid) !== -1;
		},
		likesList:function(){
			// console.log(this.eventData.likes.map((i)=> i.firstName + ' ' + i.lastName).join(', '));
			return this.eventData.likes.map((i)=> i.firstName + ' ' + i.lastName).join(', ');
            // return '';
        }
	},
	methods: {

		dateFormat: function ( tstamp, format ) {
			let date = new Date();
			date.setTime(tstamp * 1000);

			return date.format(format);
		},
		toggleBox: function () {
			this.open = !this.open;
		},
		addLike() {
			if ( !this.likeEnabled ) return;
			let postData = new URLSearchParams();
			postData.append('tx_c3json_jsondataplugin[controller]', 'JsonDataNews');
			postData.append('tx_c3json_jsondataplugin[action]', 'toggleLike');
			postData.append('tx_c3json_jsondataplugin[news]', this.eventData.uid);
			postData.append('tx_c3json_jsondataplugin[user]', this.$store.getters.getProfile.uid);

			let apiParams = Object.assign({}, ApiDefaults, {
				params: {id: 6},
				method: 'post',
				data: postData
			});

			api(apiParams)
				.then(( response ) => {

					if ( typeof response.data.newsDetail != 'undefined' ) {
						this.eventData.likes = response.data.newsDetail;
					}
					else {
						this.eventData.likes = response.data.likes;
					}
					if( response.data.action === 'added' ) {
						this.$store.commit( ADD_MY_EVENT, this.eventData.uid );
                    }
					else {
						this.$store.commit( REMOVE_MY_EVENT, this.eventData.uid );
                    }
				})
				.catch(error => console.log('Error', error));
		}
	},
	created() {}
};
</script>

<style scoped>

</style>
